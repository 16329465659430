import React from "react";
import "./Home.css";
import { Row, Col, Button, Input, Label, Table } from "reactstrap";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { connect } from "react-redux";
import XLSX from "xlsx";
import {
  getColumns,
  getColumnAliases,
  updateQuotes,
  saveCustomer,
  savePartsRevs,
  getBomDetails,
  shareFile,
} from "../../../actions";
import { TextLabel } from "../../Styled";
import { DropBox, FileInfo } from "./HomeStyled";
import { toaster } from "../../../function";
import { ToastsStore } from "react-toasts";
import Moment from "react-moment";
import { GET_QUOTE } from "../../../constants/urls";
import { currencyList } from "../../../constants/currency";
import Layouts from "../../Layouts";
import { OverlayLoader } from "../../Loader/index";
import { ImportIcon } from "../../../constants/icons";
import ImportPreviewModal from "./ImportPreviewModal";
import ImportFromHistoryModal from "./ImportFromHistoryModal";
import _ from "lodash";
import ImportFromHistory from "./ImportFromHistory";
import ExportExcel from "./ExportExcel";
import DownloadButton from "../../Buttons/DownloadButton";
import EmailButton from "../../Buttons/EmailButton";
import NeedAttentionButton from "../../Buttons/NeedAttentionButton";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isLoading: false,
      isAuth: true,
      previewData: false,
      importFromHistoryModalOpen: false,
      importFinish: props.importRecordes.bomlines.length ? true : false,
      exportEnable: props.importRecordes.bomlines.length ? true : false,
      data: [],
      file: [],
      highlightedBox: false,
      today: new Date(),
      quoteData: {},
      quoteVendors: this.props.user.user.role_id === 3 ? "Approves" : "Approves",
      currancy: "",
      quoteRef1: "$0.00",
      quoteRef2: "$0.00",
      quoteRef3: "$0.00",
      quoteRef4: "$0.00",
      quoteRef5: "$0.00",
      quoteRef6: "$0.00",
      percentageRef1: "",
      percentageRef2: "",
      percentageRef3: "",
      percentageRef4: "",
      percentageRef5: "",
      percentageRef6: "",
      showNeedAttentionRows: false,
      sheets: [],
    };
  }

  UNSAFE_componentWillMount() {
    if (this.state.isAuth) {
      this.props.getColumns(this.props.user.token);
      this.props.getColumnAliases(this.props.user.token);
    }
    if (this.props.importRecordes.quotes) {
      let quoteData = {};
      this.props.importRecordes.quotes.map((item, index) => {
        quoteData[`quote${index + 1}`] = item[`quote${index + 1}`];
        return this.setState({
          [`quoteRef${index + 1}`]: item[`quoteRef${index + 1}`],
          [`percentageRef${index + 1}`]: item[`percentageRef${index + 1}`],
        });
      });

      this.setState({ quoteData });
    }
  }

  componentWillUnmount() {
    this.setState({ isAuth: false });
  }

  toggleModal = () => {
    this.inputRef.current.value = "";
    return this.setState({ previewData: !this.state.previewData });
  };

  toggleImportFromHistoryModal = () => {
    this.setState({
      importFromHistoryModalOpen: !this.state.importFromHistoryModalOpen,
    });
  };

  closeImportFromHistoryModal = () => {
    this.setState({
      importFromHistoryModalOpen: false,
    });
  };

  showBomLines = (value) => {
    this.clearQuote();
    return this.setState({
      importFinish: value,
      exportEnable: value,
    });
  };

  fileUpload = (acceptFiles, index = 0) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    if (acceptFiles) {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          bookVBA: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[index];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws, { defval: "", header: "A" });
        this.addColumnIndex(data);

        let sheet_array = wb.SheetNames?.map((sheet, index) => ({
          'id': index,
          'name': sheet
        }));

        /* Update state */
        this.setState({ data: data, previewData: true, file: acceptFiles, sheets:  sheet_array});
        localStorage.setItem(
          "fileName",
          this.state.file && this.state.file[0] ? this.state.file[0].name : ""
        );
      };

      if (rABS) {
        reader.readAsBinaryString(acceptFiles[0]);
      } else {
        reader.readAsArrayBuffer(acceptFiles[0]);
      }
    }
  };

  addColumnIndex = (data) => {
    for (let i = 0; i < data.length; i++) {
      data[i] = Object.assign({ index: i + 1 }, data[i]);
    }
  };

  fillQuote = (e) => {
    let quoteData = this.state.quoteData;
    quoteData[e.target.name] = e.target.value;
    this.setState({ quoteData });
  };

  fillQuoteVendors = (e) => {
    if (this.props.user.user.role_id === 3) {
      return false;
    }
    return this.setState({ quoteVendors: e.target.value });
  };

  orignalField = async () => {
    if (this.props.importRecordes.excelData.length) {
      let excelKeys = Object.keys(this.props.importRecordes.excelData[0]);
      let orignalRecords = await this.props.importRecordes.excelData.map(
        (item) => {
          excelKeys.forEach((key) => {
            if (
              key.indexOf("Quote ") !== -1 ||
              key.indexOf("BOM Manager Result ") !== -1 ||
              key.indexOf("QuoteQty ") !== -1
            ) {
              item = _.omit(item, key);
            }
          });
          return item;
        }
      );
      return orignalRecords;
    }
    return false;
  };

  getPartStatusDetails = (data, ele, postQuote) => {
    try {
      let firstDistributorParam =
        data[ele["manufacturer_part_number"]][parseInt(postQuote.quotes[0])][
          "distributor"
        ];
      let firstDistributorParamkeyObject = Object.keys(
        firstDistributorParam
      )[0];
      let rohs_regex = /[Rr][Oo][Hh][Ss]\s*[Cc][Oo][Mm][Pp][Ll][Ii][Aa][Nn][Tt]\s*:\s*[Yy][Ee][Ss]/;
      return {
        part_status: [
          {
            key: "ROHS Status",
            value: firstDistributorParam[firstDistributorParamkeyObject][
              "description"
            ].match(rohs_regex) ? "ROHS Compliant: Yes" :
              firstDistributorParam[firstDistributorParamkeyObject][
                "ROHSStatus"
              ],
          },
          {
            key: "Lifecycle Status",
            value:
              firstDistributorParam[firstDistributorParamkeyObject][
                "lifecycleStatus"
              ],
          }
        ],
      };
    } catch {
      return "N/A";
    }
  };

  // getPartDescription = (data, ele, postQuote) => {
  //   try {
  //     let firstDistributorParam =
  //       data[ele["manufacturer_part_number"]][parseInt(postQuote.quotes[0])][
  //         "distributor"
  //       ];
  //     let firstDistributorParamkeyObject = Object.keys(
  //       firstDistributorParam
  //     )[0];
  //     let rohs_regex = /[Rr][Oo][Hh][Ss]\s*[Cc][Oo][Mm][Pp][Ll][Ii][Aa][Nn][Tt]\s*:\s*[Yy][Ee][Ss]/;
  //     return {
  //       description: [
  //         {
  //           key: "Description",
  //           value:
  //             firstDistributorParam[firstDistributorParamkeyObject][
  //               "description"
  //             ].match(rohs_regex) ? firstDistributorParam[firstDistributorParamkeyObject][
  //               "description"
  //             ].replace(rohs_regex, '') :
  //             firstDistributorParam[firstDistributorParamkeyObject][
  //               "description"
  //             ],
  //         },
  //         {
  //           key: "Digikey Description",
  //           value: firstDistributorParam[firstDistributorParamkeyObject]["short_description"],
  //         },
  //         {
  //           key: "Datasheet",
  //           link:
  //             firstDistributorParam[firstDistributorParamkeyObject][
  //               "datasheetLink"
  //             ],
  //           linkText: "Datasheet",
  //         }
  //       ],
  //     };
  //   } catch {
  //     return "N/A";
  //   }
  // };

  getDigikeyDescription = (data, ele, postQuote) => {
    try {
      let firstDistributorParam =
        data[ele["manufacturer_part_number"]][parseInt(postQuote.quotes[0])][
          "distributor"
        ];
      let firstDistributorParamkeyObject = Object.keys(
        firstDistributorParam
      )[0];
      return (firstDistributorParam[firstDistributorParamkeyObject]["short_description"] ? firstDistributorParam[firstDistributorParamkeyObject]["short_description"] : "N/A");
    } catch {
      return "N/A";
    }
  };

  addQuoteColummns = async (postQuote, parts) => {
    const excelData = await this.orignalField();
    let data = parts.partsDetail;
    let mfgPartkey = Object.keys(data);
    let updateRecords = await excelData.map((ele) => {
      postQuote.quotes.forEach((item, index) => {
        ele["QuoteQty " + (index + 1)] = ele["quantity"] * parseInt(item);

        ele["manufacturer_part_number"] = String(
          ele["manufacturer_part_number"]
        ).trim();
        if(index === 0) {
          ele[
            "Part Status"
          ] = this.getPartStatusDetails(data, ele, postQuote);
          // ele[
          //   "Part Description"
          // ] = this.getPartDescription(data, ele, postQuote);
          ele[
            "Digikey Description"
          ] = this.getDigikeyDescription(data, ele, postQuote);
        }
        if (mfgPartkey.includes(ele["manufacturer_part_number"])) {
          if (Object.keys(data[ele["manufacturer_part_number"]]).length === 0) {
            ele["BOM Manager Result " + (index + 1)] = {
              text: [{ class: "tdSubTitle", text: "No Result" }],
              class: "bg-light-maroon",
            };
            ele["Quote " + (index + 1)] = {
              text: [
                { class: "tdSubTitle", text: this.state.currancy + "0.00" },
              ],
              class: "bg-yellow",
            };
          } else {
            let distributorParam =
              data[ele["manufacturer_part_number"]][parseInt(item)][
                "distributor"
              ];
            let keyObject = Object.keys(distributorParam)[0];
            let quoteStatus = distributorParam[keyObject]["outcome"];

            switch (quoteStatus) {
              case "notAvailable":
                ele["BOM Manager Result " + (index + 1)] = {
                  text: [{ class: "tdSubTitle", text: "Not Available" }],
                  class: "bg-light-maroon",
                };
                ele["Quote " + (index + 1)] = {
                  text: [
                    { class: "tdSubTitle", text: this.state.currancy + "0.00" },
                  ],
                  class: "bg-yellow",
                };
                break;
              case "success":
              case "insufficientQuantity":
                let manufacturerName =
                  distributorParam[keyObject]["manufacturerName"];
                let isManufacturerMatch =
                  distributorParam[keyObject]["isManufacturerMatch"];
                let manufacturerPartNumber =
                  distributorParam[keyObject]["manufacturerPartNumber"];
                let isPartMatch = distributorParam[keyObject]["isPartMatch"];
                let unitPrice = distributorParam[keyObject]["unitPrice"];
                let availableStock =
                  distributorParam[keyObject]["availableStock"];
                let currancy = distributorParam[keyObject]["currency"];
                let currancySymbol = currencyList.find(
                  (curr) => curr.title === currancy
                );
                let moq = null;
                if (distributorParam[keyObject]["moq"]) {
                  moq = " | MOQ " + distributorParam[keyObject]["moq"];
                }
                this.setState({ currancy: currancySymbol.symbol });

                ele["BOM Manager Result " + (index + 1)] = {
                  text: [
                    {
                      class: isManufacturerMatch
                        ? "tdSubTitle"
                        : "tdItalicSubTitle",
                      text: manufacturerName,
                    },
                    { class: "tdSubTitle", text: " | " },
                    {
                      class: isPartMatch ? "tdSubTitle" : "tdItalicSubTitle",
                      text: manufacturerPartNumber,
                    },
                    { class: "tdSubTitle", text: " | " },
                    { class: "tdSubTitle", text: keyObject },
                    { class: "tdSubTitle", text: " | Cost: " },
                    { class: "tdSubTitle", text: unitPrice },
                    quoteStatus === "insufficientQuantity"
                      ? { class: "tdItalicSubTitle", text: " | Available: " }
                      : {},
                    quoteStatus === "insufficientQuantity"
                      ? { class: "tdItalicSubTitle", text: availableStock }
                      : {},
                    moq != null ? { class: "tdItalicSubTitle", text: moq } : {},
                  ],
                  class:
                    quoteStatus === "insufficientQuantity"
                      ? "bg-gray-opacity"
                      : "bg-green",
                  title:
                    quoteStatus === "insufficientQuantity"
                      ? "Insufficient Quanity"
                      : "",
                };
                ele["Quote " + (index + 1)] = {
                  text: [
                    {
                      class: "tdSubTitle",
                      text:
                        this.state.currancy +
                        (quoteStatus === "insufficientQuantity" ? "0.00" : data[ele["manufacturer_part_number"]][parseInt(item)]
                          .quotePrice),
                    },
                  ],
                  class: " ",
                };
                break;
              case "notApplicable":
              default:
                ele["BOM Manager Result " + (index + 1)] = {
                  text: [{ class: "tdSubTitle", text: "Not Applicable" }],
                  class: "bg-light-maroon",
                };
                ele["Quote " + (index + 1)] = {
                  text: [
                    { class: "tdSubTitle", text: this.state.currancy + "0.00" },
                  ],
                  class: "bg-yellow",
                };
                break;
            }
          }
        } else {
          ele["BOM Manager Result " + (index + 1)] = {
            text: [{ class: "tdSubTitle", text: "Not Applicable" }],
            class: "bg-light-maroon",
          };
          ele["Quote " + (index + 1)] = {
            text: [{ class: "tdSubTitle", text: this.state.currancy + "0.00" }],
            class: "bg-yellow",
          };
        }
      });
      return ele;
    });
    return updateRecords;
  };

  addTotalColumn = async (postQuote, records) => {
    this.clearQuote();
    let quotesDetail = records.quotesDetail;
    let updatedData = records.partsDetail;
    let totalColumn = Object.assign({}, updatedData[0]);
    Object.keys(updatedData[0]).forEach((e) => {
      totalColumn[e] = "";
    });
    await postQuote.quotes.forEach((item, index) => {
      let total = quotesDetail[item].total;
      let percentage = quotesDetail[item].percentage;
      let quoteData = this.state.quoteData;
      quoteData["quote" + (index + 1)] = item;
      this.setState({
        ...quoteData,
        ["quoteRef" + (index + 1)]: this.state.currancy + total,
        ["percentageRef" + (index + 1)]: percentage,
      });
      return (totalColumn["Quote " + (index + 1)] =
        this.state.currancy + total);
    });

    return totalColumn;
  };

  autoQuote = async () => {
    const selectedVendors = localStorage.getItem("selectedVendors");
    if (this.props.importRecordes.bomId) {
      this.setState({ isLoading: true });
      let postQuote = {
        bom_id: this.props.importRecordes.bomId,
        quotes: [
          this.state.quoteData.quote1,
          this.state.quoteData.quote2,
          this.state.quoteData.quote3,
          this.state.quoteData.quote4,
          this.state.quoteData.quote5,
          this.state.quoteData.quote6,
        ],
      };
      if (this.state.quoteVendors !== "All") {
        var selectedVendorsArray = selectedVendors.split(",");
        postQuote.filters = { distributors_list: selectedVendorsArray };
      }

      postQuote.quotes = postQuote.quotes.filter(Boolean);
      if (!postQuote.quotes.length) {
        this.setState({ isLoading: false });
        return toaster(ToastsStore, "error", "Please fill auto quote.");
      }
      try {
        const GET_QUOTE_URL =
          localStorage.getItem("isDebug") === "1"
            ? GET_QUOTE + "?debug=1"
            : GET_QUOTE;
        const res = await fetch(GET_QUOTE_URL, {
          method: "POST",
          headers: {
            authorization: "Bearer " + this.props.user.token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postQuote),
        });

        const data = await res.json();

        if (data.code >= 200 && data.code < 400) {
          let updatedData = await this.addQuoteColummns(postQuote, data.data);
          let generateTotals = { ...data.data, partsDetail: updatedData };
          let totalQuote = await this.addTotalColumn(postQuote, generateTotals);
          updatedData.push(totalQuote);
          let quotes = [
            {
              quote1: this.state.quoteData.quote1,
              quoteRef1: this.state.quoteRef1,
              percentageRef1: this.state.percentageRef1,
            },
            {
              quote2: this.state.quoteData.quote2,
              quoteRef2: this.state.quoteRef2,
              percentageRef2: this.state.percentageRef2,
            },
            {
              quote3: this.state.quoteData.quote3,
              quoteRef3: this.state.quoteRef3,
              percentageRef3: this.state.percentageRef3,
            },
            {
              quote4: this.state.quoteData.quote4,
              quoteRef4: this.state.quoteRef4,
              percentageRef4: this.state.percentageRef4,
            },
            {
              quote5: this.state.quoteData.quote5,
              quoteRef5: this.state.quoteRef5,
              percentageRef5: this.state.percentageRef5,
            },
            {
              quote6: this.state.quoteData.quote6,
              quoteRef6: this.state.quoteRef6,
              percentageRef6: this.state.percentageRef6,
            },
          ];

          this.props.updateQuotes({ bomlines: updatedData, quotes });
          toaster(ToastsStore, "success", "Parsed the BOM successfully.");
          this.setState({ exportEnable: true, isLoading: false });
        } else {
          this.setState({ isLoading: false });
          if (data.message) alert(data.message);
        }
      } catch (e) {
        this.setState({ isLoading: false });
      }
    } else {
      toaster(
        ToastsStore,
        "error",
        "Please import file to generate auto quote."
      );
      this.setState({ isLoading: false });
    }
  };

  clearQuote = () => {
    this.setState({
      quoteData: {},
      quoteRef1: "$0.00",
      quoteRef2: "$0.00",
      quoteRef3: "$0.00",
      quoteRef4: "$0.00",
      quoteRef5: "$0.00",
      quoteRef6: "$0.00",
      percentageRef1: "",
      percentageRef2: "",
      percentageRef3: "",
      percentageRef4: "",
      percentageRef5: "",
      percentageRef6: "",
      showNeedAttentionRows: false,
    });
  };

  rowNeedsAttention = (row) => {
    let rowNeedsAttention = false;
    Object.values(row).map((item) => {
      if (
        item != null &&
        typeof item === "object" &&
        item["class"] === "bg-light-maroon"
      ) {
        rowNeedsAttention = true;
      }
      return null;
    });
    return rowNeedsAttention;
  };

  render() {
    const { columns, columnAliases, importRecordes } = this.props;
    const initColumnLength = parseInt(localStorage.getItem("importColumn"));
    return (
      <Layouts>
        <OverlayLoader show={this.state.isLoading} />
        <div className="theme-form bg-dark-gray importBlock">
          <Row>
            <Col xl="3">
              <h4>Import BOM File </h4>
              <Row>
                <Col md="6" xl="12">
                  <Label
                    className={classNames(
                      "btn-block text-center importBtn selected"
                    )}
                  >
                    <ImportIcon className="mr-2" />
                    <input
                      className="d-none"
                      ref={this.inputRef}
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => this.fileUpload(e.target.files)}
                    />
                    Import BOM
                  </Label>
                </Col>
                <Col md="6" xl="12">
                  <ImportFromHistory
                    importFromHistoryModalOpen={() => {
                      this.toggleImportFromHistoryModal();
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xl="9">
              <Row>
                <Col xl="6">
                  <h4>Auto Quotes</h4>
                  <table className="table quoteTable mb-xl-0">
                    <tbody>
                      <tr>
                        <td>
                          <span>Quote 1</span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            tabIndex={1}
                            placeholder="0"
                            name="quote1"
                            value={this.state.quoteData.quote1 || ""}
                            onChange={(e) => this.fillQuote(e)}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              this.state.quoteRef1 !== "$0.00"
                                ? this.state.quoteRef1
                                : ""
                            }
                            placeholder="$0.00"
                            readOnly
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.percentageRef1}
                            placeholder="%"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Quote 2</span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            tabIndex={2}
                            placeholder="0"
                            name="quote2"
                            value={this.state.quoteData.quote2 || ""}
                            onChange={(e) => this.fillQuote(e)}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              this.state.quoteRef2 !== "$0.00"
                                ? this.state.quoteRef2
                                : ""
                            }
                            placeholder="$0.00"
                            readOnly
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.percentageRef2}
                            placeholder="%"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Quote 3</span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            tabIndex={3}
                            placeholder="0"
                            name="quote3"
                            value={this.state.quoteData.quote3 || ""}
                            onChange={(e) => this.fillQuote(e)}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              this.state.quoteRef3 !== "$0.00"
                                ? this.state.quoteRef3
                                : ""
                            }
                            placeholder="$0.00"
                            readOnly
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.percentageRef3}
                            placeholder="%"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl="6">
                  <h4>Auto Quotes</h4>
                  <table className="table quoteTable mb-xl-0">
                    <tbody>
                      <tr>
                        <td>
                          <span>Quote 4</span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            tabIndex={4}
                            placeholder="0"
                            name="quote4"
                            value={this.state.quoteData.quote4 || ""}
                            onChange={(e) => this.fillQuote(e)}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              this.state.quoteRef4 !== "$0.00"
                                ? this.state.quoteRef4
                                : ""
                            }
                            placeholder="$0.00"
                            readOnly
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.percentageRef4}
                            placeholder="%"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Quote 5</span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            tabIndex={5}
                            placeholder="0"
                            name="quote5"
                            value={this.state.quoteData.quote5 || ""}
                            onChange={(e) => this.fillQuote(e)}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              this.state.quoteRef5 !== "$0.00"
                                ? this.state.quoteRef5
                                : ""
                            }
                            placeholder="$0.00"
                            readOnly
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.percentageRef5}
                            placeholder="%"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Quote 6</span>
                        </td>
                        <td>
                          <Input
                            type="text"
                            tabIndex={6}
                            placeholder="0"
                            name="quote6"
                            value={this.state.quoteData.quote6 || ""}
                            onChange={(e) => this.fillQuote(e)}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={
                              this.state.quoteRef6 !== "$0.00"
                                ? this.state.quoteRef6
                                : ""
                            }
                            placeholder="$0.00"
                            readOnly
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={this.state.percentageRef6}
                            placeholder="%"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row>
                        <Col>
                            <Label className="customCheckbox d-inline-block">
                                <Input type="checkbox" name="checkbox1" />
                                <span className="span-text">Octo Part Columns</span>
                            </Label>
                            <Label className="customCheckbox d-inline-block">
                                <Input type="checkbox" name="checkbox2" />
                                <span className="span-text">Get Digi-Key Descriptions</span>
                            </Label>
                            <Label className="customRadio d-inline-block">
                                <Input type="radio" name="radio" />
                                <span className="span-text">Part Number Exact Match</span>
                            </Label>
                            <Label className="customRadio d-inline-block">
                                <Input type="radio" name="radio" />
                                <span className="span-text">Add Wildcard*</span>
                            </Label>
                        </Col>
                    </Row> */}
        </div>
        <div className="theme-form importBlock">
          <Row className="align-items-center">
            <Col xl="3">
              <fieldset className="fieldset">
                <legend>Vendors</legend>
                <Label className="customRadio d-inline-block">
                  <Input
                    type="radio"
                    name="vendor"
                    value="All"
                    checked={this.state.quoteVendors === "All" ? true : false}
                    onChange={(e) => this.fillQuoteVendors(e)}
                  />
                  <span className="span-text">All</span>
                </Label>
                <Label className="customRadio d-inline-block pr-0">
                  <Input
                    type="radio"
                    name="vendor"
                    value="Approves"
                    checked={
                      this.state.quoteVendors === "Approves" ? true : false
                    }
                    onChange={(e) => this.fillQuoteVendors(e)}
                  />
                  <span className="span-text">Approved</span>
                </Label>
              </fieldset>
            </Col>
            <Col xl="7 validOption">
              {/* <fieldset className="fieldset">
                <legend>Valid Options</legend>
                <Row>
                  <Col md="6" className="xl-flex">
                    <TextLabel>Valid BOM Items</TextLabel>
                    <Input type="text" />
                  </Col>
                  <Col md="6" className="xl-flex">
                    <TextLabel>Valid Alternatives</TextLabel>
                    <Input type="text" />
                  </Col>
                </Row>
              </fieldset> */}
            </Col>
            <Col xl="2">
              <Button
                className={classNames("btn-block autoQuoteBtn ", {
                  selected:
                    Object.values(this.state.quoteData).toString().length &&
                    this.props.importRecordes.bomId !== undefined,
                })}
                disabled={
                  !Object.values(this.state.quoteData).toString().length ||
                  this.props.importRecordes.bomId === undefined
                    ? true
                    : false
                }
                onClick={this.autoQuote}
              >
                Auto Quote
              </Button>
            </Col>
          </Row>
        </div>
        <div className="importBlock bg-gradient">
          {this.state.importFinish ? (
            <div className="importBlock radius-8 bg-white">
              <FileInfo>
                <TextLabel>Customer Name</TextLabel>
                <h5>{importRecordes.name}</h5>
              </FileInfo>
              <FileInfo>
                <TextLabel>Date</TextLabel>
                <h5>
                  <Moment format="DD MMM, YYYY">
                    {importRecordes.date
                      ? importRecordes.date
                      : this.state.today}
                  </Moment>
                </h5>
              </FileInfo>
              <FileInfo>
                <TextLabel>Part #REV</TextLabel>
                <h5>{importRecordes.part}</h5>
              </FileInfo>
              <FileInfo>
                <TextLabel>BOM Quote ID</TextLabel>
                <h5>{importRecordes.bomId}</h5>
              </FileInfo>
              <DownloadButton
                className="linkBtn download"
                onClick={async () => {
                  await ExportExcel(
                    this.props.user,
                    this.props.importRecordes,
                    this.props.importRecordes.invalidQuantityColumns,
                    columns,
                    true
                  );
                }}
              />
              <EmailButton
                className="linkBtn download"
                onClick={async () => {
                  await ExportExcel(
                    this.props.user,
                    this.props.importRecordes,
                    this.props.importRecordes.invalidQuantityColumns,
                    columns,
                    false
                  );

                  const bomDetails = await this.props.getBomDetails(
                    this.props.user.token,
                    this.props.importRecordes.bomId
                  );
                  let data = await this.props.shareFile(
                    this.props.user.token,
                    bomDetails?.data?.result_file_path,
                    "Result-" + bomDetails?.data?.file_name + ".xlsx"
                  );
                  if (data.status >= 200 && data.status < 400) {
                    toaster(
                      ToastsStore,
                      "success",
                      "Sent the BOM file to your registered email."
                    );
                  } else {
                    data = await this.props.shareFile(
                      this.props.user.token,
                      bomDetails?.data?.file_path,
                      bomDetails?.data?.file_name + ".xlsx"
                    );
                    if (data.status >= 200 && data.status < 400) {
                      toaster(
                        ToastsStore,
                        "success",
                        "Sent the BOM file to your registered email."
                      );
                    } else if (data.status === 404) {
                      toaster(ToastsStore, "error", "Could not find the file.");
                    } else {
                      toaster(ToastsStore, "error", "Could not get the file.");
                    }
                  }
                }}
              />
              <NeedAttentionButton
                className="linkBtn needAttention"
                onClick={() => {
                  this.setState((prevState) => {
                    return {
                      ...prevState,
                      showNeedAttentionRows: !prevState.showNeedAttentionRows,
                    };
                  });
                }}
              />
              <div className="table-responsive mh-430">
                <Table className="table-bordered table-gray hovered mb-0 table-sticky-header">
                  <thead>
                    <tr>
                      {importRecordes &&
                        Object.keys(importRecordes.bomlines[0]).map(
                          (item, index) => {
                            let colName = importRecordes.selectedColumns.find(
                              (column) => column.value === item
                            );
                            return (
                              <th
                                key={index}
                                className={classNames({
                                  "bg-green": initColumnLength <= index,
                                  "bg-blue": item.indexOf("Quote ") !== -1,
                                })}
                              >
                                {colName !== undefined ? colName.name : item}
                              </th>
                            );
                          }
                        )}
                    </tr>
                  </thead>
                  <tbody className="">
                    {/* eslint-disable-next-line array-callback-return */}
                    {importRecordes && importRecordes.bomlines.map((item, key) => {
                        if (
                          this.state.showNeedAttentionRows === false ||
                          this.rowNeedsAttention(item)
                        ) {
                          return (
                            <tr key={key}>
                              {Object.keys(item).map((col, index) => {
                                if (
                                  item[col] != null &&
                                  typeof item[col] === "object" &&
                                  item[col].description != null
                                ) {
                                  return (
                                    <td key={index}>
                                      {item[col].description.map((d) => {
                                        return (
                                          <React.Fragment>
                                            <span className="tdpartsDetailsEntry">
                                              <span className="tdpartsDetailsKey">
                                                {d.key + ": "}
                                              </span>
                                              <span className="tdpartsDetailsValue">
                                                {d.link ? (
                                                  <a
                                                    href={d.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {d.linkText}
                                                  </a>
                                                ) : d.value ? (
                                                  d.value
                                                ) : (
                                                  "n/a"
                                                )}
                                              </span>
                                            </span>
                                            <br />
                                          </React.Fragment>
                                        );
                                      })}
                                    </td>
                                  );
                                }

                                if (
                                  item[col] != null &&
                                  typeof item[col] === "object" &&
                                  item[col].part_status != null
                                ) {
                                  return (
                                    <td key={index}>
                                      {item[col].part_status.map((d) => {
                                        return (
                                          <React.Fragment>
                                            <span className="tdpartsDetailsEntry">
                                              <span className="tdpartsDetailsKey">
                                                {d.key + ": "}
                                              </span>
                                              <span className="tdpartsDetailsValue">
                                                {d.value ? (
                                                  d.value
                                                ) : (
                                                  "n/a"
                                                )}
                                              </span>
                                            </span>
                                            <br />
                                          </React.Fragment>
                                        );
                                      })}
                                    </td>
                                  );
                                }

                                if (
                                  item[col] != null &&
                                  typeof item[col] === "object"
                                ) {
                                  return (
                                    <td
                                      key={index}
                                      className={classNames(item[col].class)}
                                    >
                                      <span className="tdTitle">
                                        {item[col].title}
                                      </span>
                                      <br />
                                      {item[col].text.map((t) => {
                                        return (
                                          <span className={t.class}>
                                            {t.text}
                                          </span>
                                        );
                                      })}
                                    </td>
                                  );
                                }

                                return (
                                  <td
                                    key={index}
                                    className={
                                      importRecordes.invalidQuantityColumns.includes(
                                        key + 1
                                      ) && col === "quantity"
                                        ? classNames("bg-light-maroon")
                                        : ""
                                    }
                                  >
                                    {item[col]}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            <div className="dropBlock">
              <Row className="justify-content-center">
                <Col md="9" lg="8">
                  <Dropzone
                    onDrop={(e) => this.fileUpload(e)}
                    onDragOver={() => this.setState({ highlightedBox: true })}
                    onDragLeave={() => this.setState({ highlightedBox: false })}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <DropBox
                        className={classNames({
                          highlightedBox: this.state.highlightedBox,
                        })}
                        {...getRootProps()}
                      >
                        <input
                          {...getInputProps()}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <img src="/images/Shape.png" alt="file_icon" />
                        <img
                          className="active"
                          src="/images/Shape-fill.png"
                          alt="file_icon"
                        />
                        <h5>Drag and drop BOM file</h5>
                        <span>(.excel only)</span>
                      </DropBox>
                    )}
                  </Dropzone>
                </Col>
              </Row>
            </div>
          )}
        </div>
        {/* Preview Modal */}
        <ImportPreviewModal
          className="theme-form preview-modal"
          modal={this.state.previewData}
          toggleModal={this.toggleModal}
          toggleHistoryModal={this.closeImportFromHistoryModal}
          showBomLines={this.showBomLines}
          columns={columns}
          columnAliases={columnAliases}
          setLoading={(e) => this.setState({ isLoading: e })}
          loginUser={this.props.user.token}
          saveCustomer={this.props.saveCustomer}
          savePartsRevs={this.props.savePartsRevs}
          data={this.state.data}
          file={this.state.file}
          sheets={this.state.sheets}
          fileUpload={this.fileUpload}
        />
        <ImportFromHistoryModal
          className="theme-form preview-modal"
          modal={this.state.importFromHistoryModalOpen}
          toggle={this.toggleImportFromHistoryModal}
          user={this.props.user}
          fileUpload={this.fileUpload}
        />
      </Layouts>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  columns: state.column,
  columnAliases: state.columnAliases,
  importRecordes: state.importTable,
});

export default connect(mapStateToProps, {
  getColumns,
  getColumnAliases,
  saveCustomer,
  updateQuotes,
  savePartsRevs,
  getBomDetails,
  shareFile,
})(Home);
